<template>
  <ModelCreater :title="title" :loading="isLoading" :errors="errors" :canCreate="canCreate" @create="create">
    <CreateWheelStyled>
      <MultiselectWrapper class="type">
        <div class="type-label">Assets</div>
        <Multiselect
          :value="$v.assetsSelected.$model"
          @select="toggleAssetSelect"
          @remove="toggleAssetSelect"
          :options="assetsWithWheelPosition"
          :multiple="true"
          trackBy="id"
          label="name"
        />
      </MultiselectWrapper>
      <InputField :labelWidth="6" class="axle">
        <template v-slot:input>
          <input type="text" v-model.trim="$v.axle.$model" placeholder="Axle" />
        </template>
        <template v-slot:label>
          <span>Axle</span>
        </template>
        <template v-slot:errors v-if="$v.axle.$dirty">
          <div class="error" v-if="!$v.axle.required">Field is required</div>
          <!-- <div class="error" v-if="!$v.axle.minLength">Field must have at least {{$v.axle.$params.minLength.min}} letters.</div> -->
        </template>
      </InputField>
      <InputField :labelWidth="6" class="alias">
        <template v-slot:input>
          <input type="text" v-model.trim="$v.axlePosition.$model" placeholder="Axle Position" />
        </template>
        <template v-slot:label>
          <span>Axle Position</span>
        </template>
        <template v-slot:errors v-if="$v.axlePosition.$dirty">
          <div class="error" v-if="!$v.axlePosition.required">Field is required</div>
          <div class="error" v-if="!$v.axlePosition.minLength">
            Field must have at least {{ $v.axlePosition.$params.minLength.min }} letters.
          </div>
        </template>
      </InputField>
    </CreateWheelStyled>
  </ModelCreater>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import ModelCreater from '@/components/misc/ModelCreater'
import { InputField } from '@common/components'
import get from 'lodash/get'
import Multiselect from 'vue-multiselect'
import { required, minLength } from 'vuelidate/lib/validators'

import ASSETS_WITH_WHEEL_POSITION from '#/graphql/operations/assets/assetsWithLastPosition.gql'
import CREATE_WHEEL_MUTATION from '#/graphql/wheels/createWheel.gql'

const CreateWheelStyled = styled('div')`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: minmax(3rem, min-content);
  grid-gap: 1rem;
  align-items: center;
`

const MultiselectWrapper = styled('div')`
  ${({ hasHighestZIndex }) => hasHighestZIndex && 'z-index: 1000'};
`

export default {
  components: {
    CreateWheelStyled,
    ModelCreater,
    InputField,
    MultiselectWrapper,
    Multiselect,
  },
  data() {
    return {
      axle: '',
      axlePosition: '',
      errors: [],
      assetsWithWheelPosition: [],
      isLoading: false,
      isCreating: false,
      assetsSelected: '',
      disabledFields: ['assetID'],
    }
  },
  computed: {
    title() {
      return `Create Wheel`
    },
    canCreate() {
      return !this.$v.$invalid && !this.isCreating
    },
  },
  validations: {
    axle: {
      required,
      // minLength: minLength(5),
    },
    axlePosition: {
      required,
      minLength: minLength(2),
    },
  },
  methods: {
    async create() {
      this.isCreating = true
      try {
        const res = await this.$apollo.mutate({
          mutation: CREATE_WHEEL_MUTATION,
          variables: {
            data: {
              name: this.name,
              axlePosition: this.axlePosition,
            },
          },
        })
        const id = get(res, 'data.createWheel.id')
        if (id) {
          this.$emit('reload')
          this.$router.push({
            name: 'wheel',
            params: {
              id,
            },
          })
        }
      } catch (err) {
        this.errors.push(err)
      }
      this.isCreating = false
    },
  },
  apollo: {
    assetsWithWheelPosition: {
      query: ASSETS_WITH_WHEEL_POSITION,
      update(data) {
        this.assetsWithWheelPosition = get(data, 'assets', [])
      },
    },
  },
}
</script>
