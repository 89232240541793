var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ModelCreater', {
    attrs: {
      "title": _vm.title,
      "loading": _vm.isLoading,
      "errors": _vm.errors,
      "canCreate": _vm.canCreate
    },
    on: {
      "create": _vm.create
    }
  }, [_c('CreateWheelStyled', [_c('MultiselectWrapper', {
    staticClass: "type"
  }, [_c('div', {
    staticClass: "type-label"
  }, [_vm._v("Assets")]), _c('Multiselect', {
    attrs: {
      "value": _vm.$v.assetsSelected.$model,
      "options": _vm.assetsWithWheelPosition,
      "multiple": true,
      "trackBy": "id",
      "label": "name"
    },
    on: {
      "select": _vm.toggleAssetSelect,
      "remove": _vm.toggleAssetSelect
    }
  })], 1), _c('InputField', {
    staticClass: "axle",
    attrs: {
      "labelWidth": 6
    },
    scopedSlots: _vm._u([{
      key: "input",
      fn: function fn() {
        return [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model.trim",
            value: _vm.$v.axle.$model,
            expression: "$v.axle.$model",
            modifiers: {
              "trim": true
            }
          }],
          attrs: {
            "type": "text",
            "placeholder": "Axle"
          },
          domProps: {
            "value": _vm.$v.axle.$model
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) { return; }
              _vm.$set(_vm.$v.axle, "$model", $event.target.value.trim());
            },
            "blur": function blur($event) {
              return _vm.$forceUpdate();
            }
          }
        })];
      },
      proxy: true
    }, {
      key: "label",
      fn: function fn() {
        return [_c('span', [_vm._v("Axle")])];
      },
      proxy: true
    }, _vm.$v.axle.$dirty ? {
      key: "errors",
      fn: function fn() {
        return [!_vm.$v.axle.required ? _c('div', {
          staticClass: "error"
        }, [_vm._v("Field is required")]) : _vm._e()];
      },
      proxy: true
    } : null], null, true)
  }), _c('InputField', {
    staticClass: "alias",
    attrs: {
      "labelWidth": 6
    },
    scopedSlots: _vm._u([{
      key: "input",
      fn: function fn() {
        return [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model.trim",
            value: _vm.$v.axlePosition.$model,
            expression: "$v.axlePosition.$model",
            modifiers: {
              "trim": true
            }
          }],
          attrs: {
            "type": "text",
            "placeholder": "Axle Position"
          },
          domProps: {
            "value": _vm.$v.axlePosition.$model
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) { return; }
              _vm.$set(_vm.$v.axlePosition, "$model", $event.target.value.trim());
            },
            "blur": function blur($event) {
              return _vm.$forceUpdate();
            }
          }
        })];
      },
      proxy: true
    }, {
      key: "label",
      fn: function fn() {
        return [_c('span', [_vm._v("Axle Position")])];
      },
      proxy: true
    }, _vm.$v.axlePosition.$dirty ? {
      key: "errors",
      fn: function fn() {
        return [!_vm.$v.axlePosition.required ? _c('div', {
          staticClass: "error"
        }, [_vm._v("Field is required")]) : _vm._e(), !_vm.$v.axlePosition.minLength ? _c('div', {
          staticClass: "error"
        }, [_vm._v(" Field must have at least " + _vm._s(_vm.$v.axlePosition.$params.minLength.min) + " letters. ")]) : _vm._e()];
      },
      proxy: true
    } : null], null, true)
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }